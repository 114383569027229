<!--
 * @Author: SongYijie
 * @Date: 2020-03-26 17:41:34
 * @LastEditors: SongYijie
-->
<template>
  <div class="description">
    <h3 class="title">证件说明</h3>
    <img src="@assets/images/user/desc1.png" alt="desc1" class="table" />
    <h4 class="item">居民身份证</h4>
    <img src="@assets/images/user/desc2.png" alt="desc2" />
    <h4 class="item">港澳居民</h4>
    <img src="@assets/images/user/desc3.png" alt="desc3" />
    <img src="@assets/images/user/desc4.png" alt="desc4" />
    <h4 class="item">台湾居民</h4>
    <img src="@assets/images/user/desc5.png" alt="desc5" />
    <img src="@assets/images/user/desc6.png" alt="desc6" />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang='less' scoped>
.description {
  display: block;
  padding: 3.2vw;
}
.title {
  font-size: 5.3333vw;
  color: #000000;
  text-align: center;
}
.item {
  font-size: 4.5333vw;
  color: #222222;
  margin-bottom: 1.3333vw;
}
img {
  width: 60%;
  display: block;
  margin: 0 auto;
}
.table {
  width: 100%;
}
</style>
